import { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import "./App.css";
import {
  fetchAssetImage,
  getAccountInfo,
  getAsset,
  placeholderImage,
} from "./functions";
import { alertClasses, CircularProgress, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import appService from "./services/appService";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useReach } from "./hooks/useReach";
import useLocalStorage from "./hooks/useLocalStorage";

import * as backend from "./build/index.main.mjs";
import * as relayBackend from "./build/relay/index.main.mjs";
//import * as relayBackend from "./build/relay/index.main.mjs";
import { Image } from "react-bootstrap";
import "./Dice.css";
import { random } from "lodash";
import { fontSize } from "@mui/system";

const { REACT_APP_NETWORK_PROVIDER } = process.env;

const providerEnv =
  REACT_APP_NETWORK_PROVIDER ||
  localStorage.getItem("providerEnv") ||
  "TestNet";

const algoexplorer =
  providerEnv === "TestNet"
    ? "https://node.testnet.algoexplorerapi.io"
    : "https://node.algoexplorerapi.io";

const App = (props) => {
  const reach = useReach();
  const [addr, setAddr] = useLocalStorage("addr", null);
  const navigate = useNavigate();
  const { appId } = useParams();
  console.log(appId);
  const cubeRef = useRef(null);
  const frontRef = useRef(null);
  const backRef = useRef(null);
  const topRef = useRef(null);
  const leftRef = useRef(null);
  const rightRef = useRef(null);
  const bottomRef = useRef(null);
  const initialState = {
    acc: null,
    addrs:
      localStorage.getItem("state") &&
      (Object.keys(JSON.parse(localStorage.getItem("state"))?.memo2) || []).map(
        (el) => ({ addr: el })
      ),
    success: false,
    confetti: false,
    image: null,
    dices: [...Array(6).keys()].map(() => ({ appId })),
    images: [...Array(6).keys()].map(() => ({ url: "" })),
    ready: false,
    claim: null,
    remaining: -1,
  };
  const [counter, setCounter] = useState(0);
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [claiming, setClaiming] = useState(false);
  const [smashing, setSmashing] = useState(false);

  useEffect(() => {
    if (addr) {
      handleConnect();
    }
  }, []);

  useEffect(() => {
    //if (!state.acc) return;
    if (state.ready) return;
    handleRoll();
  }, [state]);

  useEffect(() => {
    console.log(counter);
  });

  const handleConnect = async () => {
    try {
      console.log("Connecting ...");
      let acc;
      if (addr) {
        acc = await reach.connectAccount({ addr });
      } else {
        acc = await reach.getDefaultAccount();
        setAddr(acc.networkAccount.addr);
      }
      const balAtomic = await reach.balanceOf(acc);
      const bal = reach.formatCurrency(balAtomic, 4);
      const accInfo = await getAccountInfo(acc.networkAccount.addr);
      /*
      const assets = {};
      const images = {};
      for (let i in accInfo.assets) {
        const asset = accInfo.assets[i];
        const assetId = asset["asset-id"];
        let asa = await getAsset(assetId).catch(console.dir);
        let image = await fetchAssetImage(asa);
        if (image.match(/#arc3/)) continue;
        console.log({ asa, image });
        assets[assetId] = asa;
        console.log({ asa });
        images[assetId] = image;
      }
      */
      setState({
        ...state,
        acc: {
          ...acc,
          ...accInfo,
        },
        addr,
        balAtomic,
        bal,
      });
    } catch (e) {
      alert(e);
    }
  };

  const handleClose = async () => {
    if (!state.acc) return;
    const ctc = state.acc.contract(backend, parseInt(state.appId));
    await backend.Bob(ctc, {
      signal: async () => {
        await reach.wait(4);
        await appService.removeDice(state.appId);
        navigate("/");
      },
    });
  };

  const randomImage = () =>
    [
      "https://infura-ipfs.io/ipfs/bafkreidcp2r3vg35ctvnbxgozhit4rnvhlfweead2vuouryum2oxfetinq",
      "https://infura-ipfs.io/ipfs/bafkreibdqklouehcapms4mi366cugs7wqb7kj2k7np6exrfm6hsfkvznaa",
      "https://cf-ipfs.com/ipfs/bafkreigzriz335q3s2ud5nof5as6bcwema6syv4uavqvdyd4ofumaovgty",
      "https://algoxnft.mypinata.cloud/ipfs/Qmbmj8EWZLKFNzxz6Ew1Ye8VARynebXdE3C5yD2tsaepMf",
      "https://algoxnft.sfo3.digitaloceanspaces.com/cached_images/556321127/556321127x600.webp",
    ][Math.floor(Math.random() * 5)];

  const randomSide = () =>
    ["center", "top", "bottom", "left", "right"][
      Math.floor(Math.random() * 5) + 1
    ];
  const randomAngle = () => Math.floor(Math.random() * 6) + 1;

  const rollDice = (die, ra) => {
    const cube = cubeRef.current;
    const front = frontRef.current;
    const left = leftRef.current;
    const back = backRef.current;
    const top = topRef.current;
    const right = rightRef.current;
    const bottom = bottomRef.current;
    cube.style.animation = "animate 1.4s linear";
    switch (die) {
      case 0:
        front.style.background = `url('${state.images[0]}')`;
        top.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[1]}')`;
        left.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[2]}')`;
        bottom.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[3]}')`;
        right.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[4]}')`;
        back.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[5]}')`;
        break;
      case 1:
        front.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[0]}')`;
        top.style.background = `url('${state.images[1]}')`;
        left.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[2]}')`;
        bottom.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[3]}')`;
        right.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[4]}')`;
        back.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[5]}')`;
        break;
      case 2:
        front.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[0]}')`;
        top.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[1]}')`;
        left.style.background = `url('${state.images[2]}')`;
        bottom.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[3]}')`;
        right.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[4]}')`;
        back.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[5]}')`;
        break;
      case 3:
        front.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[0]}')`;
        top.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[1]}')`;
        left.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[2]}')`;
        bottom.style.background = `url('${state.images[3]}')`;
        right.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[4]}')`;
        back.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[5]}')`;
        break;
      case 4:
        front.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[0]}')`;
        top.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[1]}')`;
        left.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[2]}')`;
        bottom.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[3]}')`;
        right.style.background = `url('${state.images[4]}')`;
        back.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[5]}')`;
        break;
      case 5:
        front.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[0]}')`;
        top.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[1]}')`;
        left.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[2]}')`;
        bottom.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[3]}')`;
        right.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[4]}')`;
        back.style.background = `url('${state.images[5]}')`;
        break;
      // XXX
      case -1:
        front.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[0]}')`;
        top.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[1]}')`;
        left.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[2]}')`;
        bottom.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[3]}')`;
        right.style.background = `linear-gradient( rgba(0.9, 0.9, 0.9, 1), rgba(0, 0, 0, 0.6) ),url('${state.images[4]}')`;
        back.style.background = `url('${state.images[5]}')`;
        break;
      default:
    }
    bottom.style.backgroundSize = `contain`;
    front.style.backgroundSize = `contain`;
    left.style.backgroundSize = `contain`;
    back.style.backgroundSize = `contain`;
    top.style.backgroundSize = `contain`;
    right.style.backgroundSize = `contain`;
    bottom.style.backgroundSize = `contain`;
    front.style.backgroundPosition = randomSide();
    left.style.backgroundPosition = randomSide();
    back.style.backgroundPosition = randomSide();
    right.style.backgroundPosition = randomSide();
    top.style.backgroundPosition = randomSide();
    bottom.style.backgroundPosition = randomSide();
    const angleArray = [
      [0, 0, 0],
      [-310, -362, -38],
      [-400, -320, -2],
      [135, -217, -88],
      [-224, -317, 5],
      [-47, -219, -81],
      [-133, -360, -53],
    ];
    cube.style.transform =
      "rotateX(" +
      angleArray[ra][0] +
      "deg) rotateY(" +
      angleArray[ra][1] +
      "deg) rotateZ(" +
      angleArray[ra][2] +
      "deg)";
    cube.style.transition = "1s linear";
    cube.addEventListener("animationend", function (e) {
      cube.style.animation = "";
    });
  };

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  const handleRoll = async () => {
    if (!state.acc) return;
    console.log("ROLLING");
    setLoading(true);
    const dices = shuffle(await appService.getDices());
    console.log({ dices });
    if (dices.length === 0) {
      setState({
        ...state,
        empty: true,
        ready: true,
      });
      setLoading(false);
      return;
    }

    let index = Math.floor(Math.random() * dices.length);
    //let index = 0;
    let assets;
    let count = 0;
    let select = [];
    while (index < dices.length) {
      const { escrow } = dices[index];
      const accInfo = await getAccountInfo(escrow);
      assets = accInfo.assets;
      //if (assets.length <= 0) continue;
      select.push(dices[index]);
      if (count >= 6) break;
      index++;
    }
    console.log(select);
    //const { appId } = dices[index - 1];
    console.log({ dices, appId, assets });
    const ctc = state.acc.contract(backend, parseInt(appId));
    const next = await ctc.v.next();
    const tokens = await ctc.v.tokens();
    const remaining = await ctc.v.remaining();
    const exchange = await ctc.v.exchange();
    const fNext = next[0] === "Some" ? reach.bigNumberToNumber(next[1]) : 0;
    const fTokens =
      tokens[0] === "Some"
        ? tokens[1].map((el) => reach.bigNumberToNumber(el))
        : [];
    const fRemaining =
      remaining[0] === "Some" ? reach.bigNumberToNumber(remaining[1]) : 0;
    const fExchange =
      exchange[0] === "Some" ? reach.bigNumberToNumber(exchange[1]) : 0;
    const die = fTokens.indexOf(fNext);
    const images = (await Promise.all(fTokens.map((el) => getAsset(el)))).map(
      (el) => el.url
    );
    console.log({ images });
    //const images = (await Promise.all(fTokens.map((el) => state.images[el])))

    const ra = randomAngle();
    fTokens.forEach(async (el) => console.log(await getAsset(el)));
    console.log({
      die,
      ra,
      images,
      next: fNext,
      tokens: fTokens,
      remaining: fRemaining,
      exchange: fExchange,
      ready: true,
    });
    //const ctc = state.acc.contract(relayBackend, 77853302);
    new Promise((resolve) => resolve()) // TODO revert back to relay touch for roll payments
      //await ctc.a
      //  .touch()
      .then(() => {
        setState({
          ...state,
          ra,
          die,
          appId,
          index,
          next: fNext,
          tokens: fTokens,
          remaining: fRemaining,
          exchange: fExchange,
          assets: assets.map((el) => ({ ...el, image: randomImage() })),
          images,
          ready: true,
        });
        setCounter(0);
      })
      .catch(console.dir);
    setLoading(false);
  };

  return (
    <>
      {state.dices && (
        <>
          {state.ready ? (
            <div className="container">
              {state.remaining > 0 && (
                <Typography variant="h1" className="text-light">
                  {counter}
                </Typography>
              )}
              <div
                ref={cubeRef}
                onClick={
                  claiming
                    ? () => {}
                    : () => {
                        setCounter(counter + 1);
                        const angle = Math.floor(Math.random() * 7);
                        const win = state.die + 1 === angle;
                        rollDice(state.die, angle);
                        if (win) {
                          const ctc = state.acc.contract(
                            backend,
                            parseInt(state.appId)
                          );
                          setState({
                            ...state,
                            claim: () =>
                              ((ctcR) =>
                                state.acc
                                  .tokenAccept(parseInt(state.next))
                                  .then(() =>
                                    ctcR.a.touch(100000 * (counter + 1))
                                  )
                                  .then(() => ctc.a.touch()))(
                                state.acc.contract(relayBackend, 77853302)
                              ),
                          });
                        } else {
                          setState({
                            ...state,
                            claim: null,
                          });
                        }
                        //})
                      }
                }
                className="cube"
                id="cube"
              >
                <div ref={frontRef} className="front">
                  <span className="fas fa-circle"></span>
                </div>
                <div ref={backRef} className="back">
                  <pre className="firstPre">
                    <span className="fas fa-circle"></span>{" "}
                    <span className="fas fa-circle"></span>{" "}
                    <span className="fas fa-circle"></span>
                  </pre>
                  <br />
                  <pre className="secondPre">
                    <span className="fas fa-circle"></span>{" "}
                    <span className="fas fa-circle"></span>{" "}
                    <span className="fas fa-circle"></span>
                  </pre>
                </div>
                <div ref={topRef} className="top">
                  <span className="fas fa-circle"></span>
                  <span className="fas fa-circle"></span>
                </div>
                <div ref={leftRef} className="left">
                  <span className="fas fa-circle"></span>
                  <span className="fas fa-circle"></span>
                  <span className="fas fa-circle"></span>
                </div>
                <div ref={rightRef} className="right">
                  <span className="fas fa-circle"></span>
                  <span className="fas fa-circle"></span>
                  <span className="fas fa-circle"></span>
                  <span className="fas fa-circle"></span>
                  <span className="fas fa-circle"></span>
                </div>
                <div ref={bottomRef} className="bottom">
                  <span className="fas fa-circle"></span>
                  <span className="fas fa-circle"></span>
                  <span className="fas fa-circle"></span>
                  <span className="fas fa-circle"></span>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                textAlign: "center",
                height: "60vh",
                verticalAlign: "middle",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                color: "white",
                fontSize: "30px",
              }}
            >
              <CircularProgress color="inherit" size={100} />
            </div>
          )}
        </>
      )}
      {state.claim && state.remaining > 0 && (
        <Box sx={{ m: 5 }}>
          <Button
            disabled={claiming}
            style={{
              position: "fixed",
              bottom: "0px",
              left: "0px",
            }}
            onClick={async () => {
              setClaiming(true);
              await state.claim().catch(console.dir);
              setCounter(0);
              setClaiming(false);
              window.location.reload();
            }}
            className="w-100 rounded-0"
          >
            {!claiming ? "Claim" : "Claiming..."}
          </Button>
        </Box>
      )}
      {state.remaining === 0 && (
        <Box sx={{ m: 5 }}>
          <Button
            disabled={smashing}
            variant="warning"
            style={{
              position: "fixed",
              bottom: "0px",
              left: "0px",
            }}
            onClick={async () => {
              setSmashing(true);
              await handleClose().catch(console.dir);
              setState(initialState);
              setSmashing(false);
            }}
            className="w-100"
          >
            {!smashing ? "Smash" : "Smashing..."}
          </Button>
        </Box>
      )}
    </>
  );
};

export default App;
