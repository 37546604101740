import axios from "axios";
const { REACT_APP_NETWORK_PROVIDER } = process.env;
const providerEnv = REACT_APP_NETWORK_PROVIDER || "TestNet";
let auction_api_endpoint = "https://algoapiv1.herokuapp.com";
const plans = {
  dice: "36708cbe39a6c9857c278cd87d73287d",
  relay: "40e0dee6e310bbc535190611afcc6e6d",
};
export default {
  getDices: async () => {
    const id = plans.dice;
    return (
      (await axios.post(`${auction_api_endpoint}/api/v2/apps`, { id }))?.data ||
      0
    );
  },
  createDice: async (data) => {
    const id = plans.dice;
    return (
      (await axios.post(`${auction_api_endpoint}/api/v2/launch`, { id }))
        ?.data || 0
    );
  },
  removeDice: async (id) => {
    return (
      (await axios.delete(`${auction_api_endpoint}/api/v2/apps/${id}`))?.data ||
      0
    );
  },
  createRelay: async (data) => {
    const id = plans.relay;
    return (
      (await axios.post(`${auction_api_endpoint}/api/v2/launch`, { id }))
        ?.data || 0
    );
  },
};
