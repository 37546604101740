import { default as MyAlgoConnect } from "@randlabs/myalgo-connect";
import algosdk from "algosdk";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import "./App.css";
import { getAccountInfo, getAsset } from "./functions";
import { CircularProgress, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import appService from "./services/appService";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { alpha, styled } from '@mui/material/styles';
import { useReach } from "./hooks/useReach";
import useLocalStorage from "./hooks/useLocalStorage";

import * as backend from "./build/index.main.mjs";

const { REACT_APP_NETWORK_PROVIDER } = process.env;

const providerEnv =
  REACT_APP_NETWORK_PROVIDER ||
  localStorage.getItem("providerEnv") ||
  "TestNet";

const algoexplorer =
  providerEnv === "MainNet"
    ? "https://node.testnet.algoexplorerapi.io"
    : "https://node.algoexplorerapi.io";


    const CssTextField = styled(TextField)({
      '& .MuiTextField-root': {
        color: 'white',
      },
      '& label.Mui-focused': {
        color: 'white',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
          color: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        },
      },
      '& .MuiOutlinedInput-input': {
        '&.Mui-disabled': {
          color: 'white',
          '-webkit-text-fill-color': 'inherit',
        },
      },
    });




function App() {
  const reach = useReach();
  const [addr, setAddr] = useLocalStorage("addr", null);
  const navigate = useNavigate();
  const { poolId } = useParams();
  const [appId, asset0, asset1, asset2, asset3, asset4, asset5, asset6] = poolId
    ? poolId.split("-")
    : [];
  const initialState = {
    acc: null,
    addrs:
      localStorage.getItem("state") &&
      (Object.keys(JSON.parse(localStorage.getItem("state"))?.memo2) || []).map(
        (el) => ({ addr: el })
      ),
    success: false,
    confetti: false,
  };
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({
    ASSET0: asset0,
    ASSET1: asset1,
    ASSET2: asset2,
    ASSET3: asset3,
    ASSET4: asset4,
    ASSET5: asset5,
    ASSET6: asset6,
  });

  useEffect(() => {
    if (addr) {
      handleConnect();
    }
  }, []);

  const handleChange = async ({ target }) => {
    let { name, value } = target;
    console.log({ name, value });
    switch (name) {
      case "ASSETID":
        let { id: newId = 0, decimals: DECIMALS, creator: CREATOR } = value;
        // try again to get asset info if not in option value
        if (!DECIMALS) {
          let { decimals } = await getAsset(newId);
          DECIMALS = decimals;
        }
        setQuery({
          ...query,
          [name]: newId,
          DECIMALS,
        });
        break;
      case "SWAPT":
      case "SWAPF":
      case "INFO":
      case "EXCHANGE":
      case "PASS":
      case "PLAN":
      case "AMT":
      case "TYPE":
      case "METHOD":
      case "SKIPCHECK":
        value = parseInt(value);
        break;
      default:
        break;
    }
    setQuery({ ...query, [name]: value });
  };

  const handleConnect = async () => {
    try {
      console.log("Connecting ...");
      let acc;
      if (addr) {
        acc = await reach.connectAccount({ addr });
      } else {
        acc = await reach.getDefaultAccount();
        setAddr(acc.networkAccount.addr);
      }
      const balAtomic = await reach.balanceOf(acc);
      const bal = reach.formatCurrency(balAtomic, 4);
      const accInfo = await getAccountInfo(acc.networkAccount.addr);
      setState({
        ...state,
        acc: {
          ...acc,
          ...accInfo,
        },
        addr,
        balAtomic,
        bal,
      });
    } catch (e) {
      alert(e);
    }
  };

  const handleDisconnect = () => setState(initialState);

  const handleDeposit = async () => {
    if (!state.acc) return;
    // TODO add validation
    setLoading(true);
    // XXX get app info
    const { info: appId } = await appService.createDice();
    const ctc = state.acc.contract(backend, parseInt(appId));
    await Promise.all([
      backend.Contractee(ctc, {}),
      backend.Alice(ctc, {
        getParams: () => ({
          tokens: [asset0, asset1, asset2, asset3, asset4, asset5, asset6],
        }),
      }),
    ]);
    setLoading(false);
  };

  const handleClose = async () => {
    if (!state.acc) return;
    // TODO add validation
    //setLoading(true);
    const ctc = state.acc.contract(backend, parseInt(appId));
    await backend.Bob(ctc, {});
  };

  const handleRoll = async () => {
    if (!state.acc) return;
    // TODO add validation
    //setLoading(true);
    const ctc = state.acc.contract(backend, parseInt(appId));
    await ctc.a.touch();
  };
  const handleRoll2 = async () => {
    if (!state.acc) return;
    const myAlgoWallet = new MyAlgoConnect();
    const algodClient = new algosdk.Algodv2("", algoexplorer, "");
    const params = await algodClient.getTransactionParams().do();
    params.fee = 9;
    const from = state.acc.networkAccount.addr;
    const txns = [
      algosdk.makeApplicationNoOpTxnFromObject({
        from,
        suggestedParams: params,
        appIndex: parseInt(74985032),
        appArgs: [
          new Uint8Array(Buffer.from("Bg==", "base64")),
          new Uint8Array(Buffer.from("AAAAAAAAAAA=", "base64")),
          new Uint8Array(Buffer.from("", "base64")),
        ],
        foreignAssets: [71567479],
      }),
      algosdk.makeApplicationNoOpTxnFromObject({
        from,
        suggestedParams: params,
        appIndex: parseInt(74992338),
        appArgs: [
          new Uint8Array(Buffer.from("Bg==", "base64")),
          new Uint8Array(Buffer.from("AAAAAAAAAAA=", "base64")),
          new Uint8Array(Buffer.from("", "base64")),
        ],
        foreignAssets: [71857600],
      }),
    ];
    //let txgroup = algosdk.assignGroupID(txns, from);
    let stxn = await myAlgoWallet.signTransaction(
      txns.map((el) => el.toByte())
    );
    let res = await algodClient
      .sendRawTransaction(stxn.map((el) => el.blob))
      .do();
    console.log(res);
  };

  return (
    <>
      <Container className="p-5 text-light">
        <Box sx={{ m: 3 }}>
          <Typography variant="h4">Build dice</Typography>
        </Box>
        {[...Array(7).keys()].map((i) => (
          <>
            <Box sx={{ m: 3 }}>
              <Typography variant="heading">Side {i + 1}</Typography>
            </Box>
            <Box sx={{ m: 3 }}>
            <CssTextField disabled
                defaultValue={query[`ASSET${i}`]} label="Asset id" id="custom-css-outlined-input" />
              {false && <TextField
                disabled
                defaultValue={query[`ASSET${i}`]}
                name={`ASSET${i}`}
                fullWidth
                variant="outlined"
                label="Amount"
                onChange={handleChange}
              />}
            </Box>
          </>
        ))}
        {!loading ? (
          <>
            <Box sx={{ m: 5 }}>
              <Button onClick={handleDeposit} className="w-100">
                Deposit
              </Button>
            </Box>
            {false && <>
            <Box sx={{ m: 5 }}>
              <Button onClick={handleRoll} className="w-100">
                Roll
              </Button>
            </Box>
            <Box sx={{ m: 5 }}>
              <Button onClick={handleRoll2} className="w-100">
                Roll2
              </Button>
            </Box>
            <Box sx={{ m: 5 }}>
              <Button onClick={handleClose} className="w-100">
                Close
              </Button>
            </Box>
            </>}
          </>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
      </Container>
    </>
  );
}

export default App;
