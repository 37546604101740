import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import "./App.css";
import {
  fetchAssetImage,
  getAccountInfo,
  getAsset,
  placeholderImage,
} from "./functions";
import {
  CircularProgress,
  Divider,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import appService from "./services/appService";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// AssetSelector
import AssetSellector from "./components/AssetSelector";

// Noodle
import { useReach } from "./hooks/useReach";
import useLocalStorage from "./hooks/useLocalStorage";

import { styled } from "@mui/material/styles";

import React, { useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";

import { CubeTextureLoader, MeshBasicMaterial, CubeTexture } from "three";
import LogoCube from "./LogoCube";
import { Card, Col, Image, Row } from "react-bootstrap";
import classNames from "classnames";

function Dice(props) {
  const loader = new CubeTextureLoader();
  loader.setPath("/");
  const images = [
    "https://infura-ipfs.io/ipfs/bafkreig3b2yl32qmuwoqijrt5okazenyszau66wrioepv6k3lbea2fkbnu",
    "https://infura-ipfs.io/ipfs/bafkreig3b2yl32qmuwoqijrt5okazenyszau66wrioepv6k3lbea2fkbnu",
    "https://infura-ipfs.io/ipfs/bafkreig3b2yl32qmuwoqijrt5okazenyszau66wrioepv6k3lbea2fkbnu",
    "https://infura-ipfs.io/ipfs/bafkreig3b2yl32qmuwoqijrt5okazenyszau66wrioepv6k3lbea2fkbnu",
    "https://infura-ipfs.io/ipfs/bafkreig3b2yl32qmuwoqijrt5okazenyszau66wrioepv6k3lbea2fkbnu",
    "https://infura-ipfs.io/ipfs/bafkreig3b2yl32qmuwoqijrt5okazenyszau66wrioepv6k3lbea2fkbnu",
    "https://infura-ipfs.io/ipfs/bafkreig3b2yl32qmuwoqijrt5okazenyszau66wrioepv6k3lbea2fkbnu",
  ];
  const textureCube = loader.load(images);
  const material = new MeshBasicMaterial({
    color: 0xffffff,
    envMap: textureCube,
  });

  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef();
  // Hold state for hovered and clicked events
  const [hovered, hover] = useState(false);
  const [clicked, click] = useState(false);
  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame((state, delta) => {
    ref.current.rotation.x += 0.01;
    ref.current.rotation.y += 0.01;
  });
  // Return the view, these are regular Threejs elements expressed in JSX
  return (
    <mesh
      {...props}
      ref={ref}
      scale={clicked ? 2 : 1}
      onClick={(event) => click(!clicked)}
      onPointerOver={(event) => hover(true)}
      onPointerOut={(event) => hover(false)}
    >
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={"orange"} />
    </mesh>
  );
}

const { REACT_APP_NETWORK_PROVIDER } = process.env;

const providerEnv =
  REACT_APP_NETWORK_PROVIDER ||
  localStorage.getItem("providerEnv") ||
  "TestNet";

function App() {
  const reach = useReach();
  const [addr, setAddr] = useLocalStorage("addr", null);
  const navigate = useNavigate();
  const { poolId } = useParams();
  const [_, asset0, asset1, asset2, asset3, asset4, asset5, asset6] = poolId
    ? poolId.split("-")
    : [];
  const initialState = {
    acc: null,
    addrs:
      localStorage.getItem("state") &&
      (Object.keys(JSON.parse(localStorage.getItem("state"))?.memo2) || []).map(
        (el) => ({ addr: el })
      ),
    success: false,
    confetti: false,
  };
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({
    ASSET0: asset0,
    ASSET1: asset1,
    ASSET2: asset2,
    ASSET3: asset3,
    ASSET4: asset4,
    ASSET5: asset5,
    ASSET6: asset6,
  });
  const [selection, setSelection] = useState([]);

  useEffect(() => {
    if (addr) {
      handleConnect();
    }
  }, []);

  useEffect(() => {
    console.log(selection);
  });

  const handleChange = async ({ target }) => {
    let { name, value } = target;
    console.log({ name, value });
    switch (name) {
      case "ASSETID":
        let { id: newId = 0, decimals: DECIMALS, creator: CREATOR } = value;
        // try again to get asset info if not in option value
        if (!DECIMALS) {
          let { decimals } = await getAsset(newId);
          DECIMALS = decimals;
        }
        setQuery({
          ...query,
          [name]: newId,
          DECIMALS,
        });
        break;
      case "SWAPT":
      case "SWAPF":
      case "INFO":
      case "EXCHANGE":
      case "PASS":
      case "PLAN":
      case "AMT":
      case "TYPE":
      case "METHOD":
      case "SKIPCHECK":
        value = parseInt(value);
        break;
      default:
        break;
    }
    setQuery({ ...query, [name]: value });
  };

  const handleConnect = async () => {
    try {
      console.log("Connecting ...");
      let acc;
      if (addr) {
        acc = await reach.connectAccount({ addr });
      } else {
        acc = await reach.getDefaultAccount();
        setAddr(acc.networkAccount.addr);
      }
      const balAtomic = await reach.balanceOf(acc);
      const bal = reach.formatCurrency(balAtomic, 4);
      const accInfo = await getAccountInfo(acc.networkAccount.addr);
      const assets = {};
      const images = {};
      for (let i in accInfo.assets) {
        const asset = accInfo.assets[i];
        const assetId = asset["asset-id"];
        let asa = await getAsset(assetId).catch(console.dir);
        let image = await fetchAssetImage(asa);
        if (image.match(/#arc3/)) continue;
        console.log({ asa, image });
        assets[assetId] = asa;
        console.log({ asa });
        images[assetId] = image;
      }
      setState({
        ...state,
        acc: {
          ...acc,
          ...accInfo,
        },
        assets,
        images,
        addr,
        balAtomic,
        bal,
      });
    } catch (e) {
      alert(e);
    }
  };

  const handleDisconnect = () => setState(initialState);

  const handleDiceCreate = async () => {
    if (selection.length < 6) {
      alert("select 6 assets");
      return;
    }
    // TODO add validation
    setLoading(true);
    //const { info } = await appService.createDice();
    const diceId = [1, ...selection].join("-");
    navigate(`/dice/${diceId}`);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <Container className="pb-5">
          {state.acc && state.acc.assets.length > 0 ? (
            <>
              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" className="text-center text-light">
                  -Build dice-
                </Typography>
              </Box>
              <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }} className="pb-5">
              {state.acc.assets.map((el, i) =>
                ((asset) =>
                  ((assetId, amount) =>
                    ((image, asa) => (
                      <Grid item
                        key={assetId}
                        className={classNames({
                          "asset-grid-item": true,
                          selected: selection.includes(
                            state.acc.assets[i]["asset-id"]
                          ),
                          "d-none": amount <= 0,
                        })}
                        xs={3}
                        sm={2}
                        rowSpacing={1}
                        columnSpacing={1}
                      >
                        <Image
                          fluid
                          src={image}
                          onClick={() => {
                            if (
                              selection.includes(
                                state.acc.assets[i]["asset-id"]
                              )
                            ) {
                              setSelection(
                                selection.filter(
                                  (el) => el !== state.acc.assets[i]["asset-id"]
                                )
                              );
                            } else if (selection.length <= 6) {
                              setSelection(
                                Array.from(
                                  new Set([
                                    ...selection,
                                    state.acc.assets[i]["asset-id"],
                                  ])
                                )
                              );
                            }
                          }}
                        />
                        <center style={{ color: "white" }}>{assetId}</center>
                        <center style={{ color: "white" }}>
                          {asa?.assetname}
                        </center>
                        <center style={{ color: "white" }}>{amount}</center>
                      </Grid>
                    ))(state.images[assetId], state.assets[assetId]))(
                    asset["asset-id"],
                    asset["amount"]
                  ))(state.acc.assets[i])
              )}
              </Grid>
            </>
          ) : (
            "Loading"
          )}
      </Container>
      <Box sx={{ m: 5 }}>
          <Button
            variant="primary"
            style={{
              position: "fixed",
              bottom: "0px",
              left: "0px",
            }}
            onClick={handleDiceCreate}
            className="w-100 rounded-0">
            Create Dice
          </Button>
        </Box>
    </>
  );
}

export default App;
